const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['billings/required_card'] = require('../middleware/billings/required_card.js')
middleware['billings/required_card'] = middleware['billings/required_card'].default || middleware['billings/required_card']

middleware['billings/required_enquete'] = require('../middleware/billings/required_enquete.js')
middleware['billings/required_enquete'] = middleware['billings/required_enquete'].default || middleware['billings/required_enquete']

middleware['billings/required_entry_event'] = require('../middleware/billings/required_entry_event.js')
middleware['billings/required_entry_event'] = middleware['billings/required_entry_event'].default || middleware['billings/required_entry_event']

middleware['billings/required_plan'] = require('../middleware/billings/required_plan.js')
middleware['billings/required_plan'] = middleware['billings/required_plan'].default || middleware['billings/required_plan']

middleware['not_accessible'] = require('../middleware/not_accessible.js')
middleware['not_accessible'] = middleware['not_accessible'].default || middleware['not_accessible']

middleware['not_authenticated'] = require('../middleware/not_authenticated.js')
middleware['not_authenticated'] = middleware['not_authenticated'].default || middleware['not_authenticated']

middleware['not_production'] = require('../middleware/not_production.ts')
middleware['not_production'] = middleware['not_production'].default || middleware['not_production']

middleware['only_organized_biz'] = require('../middleware/only_organized_biz.ts')
middleware['only_organized_biz'] = middleware['only_organized_biz'].default || middleware['only_organized_biz']

middleware['only_organized_school'] = require('../middleware/only_organized_school.ts')
middleware['only_organized_school'] = middleware['only_organized_school'].default || middleware['only_organized_school']

middleware['personal_detail_registered'] = require('../middleware/personal_detail_registered.js')
middleware['personal_detail_registered'] = middleware['personal_detail_registered'].default || middleware['personal_detail_registered']

middleware['portfolios/authenticated_created_user'] = require('../middleware/portfolios/authenticated_created_user.js')
middleware['portfolios/authenticated_created_user'] = middleware['portfolios/authenticated_created_user'].default || middleware['portfolios/authenticated_created_user']

middleware['portfolios/authenticated'] = require('../middleware/portfolios/authenticated.js')
middleware['portfolios/authenticated'] = middleware['portfolios/authenticated'].default || middleware['portfolios/authenticated']

middleware['portfolios/not_authenticated'] = require('../middleware/portfolios/not_authenticated.js')
middleware['portfolios/not_authenticated'] = middleware['portfolios/not_authenticated'].default || middleware['portfolios/not_authenticated']

middleware['redirect_to_new_page'] = require('../middleware/redirect_to_new_page.ts')
middleware['redirect_to_new_page'] = middleware['redirect_to_new_page'].default || middleware['redirect_to_new_page']

middleware['reject_direct_access'] = require('../middleware/reject_direct_access.ts')
middleware['reject_direct_access'] = middleware['reject_direct_access'].default || middleware['reject_direct_access']

middleware['remove_trailing_slash'] = require('../middleware/remove_trailing_slash.ts')
middleware['remove_trailing_slash'] = middleware['remove_trailing_slash'].default || middleware['remove_trailing_slash']

middleware['set_after_login_path_query_to_cookie'] = require('../middleware/set_after_login_path_query_to_cookie.ts')
middleware['set_after_login_path_query_to_cookie'] = middleware['set_after_login_path_query_to_cookie'].default || middleware['set_after_login_path_query_to_cookie']

middleware['sony_ip_restriction'] = require('../middleware/sony_ip_restriction.js')
middleware['sony_ip_restriction'] = middleware['sony_ip_restriction'].default || middleware['sony_ip_restriction']

middleware['vook_student'] = require('../middleware/vook_student.ts')
middleware['vook_student'] = middleware['vook_student'].default || middleware['vook_student']

middleware['vook_users'] = require('../middleware/vook_users.js')
middleware['vook_users'] = middleware['vook_users'].default || middleware['vook_users']

export default middleware
